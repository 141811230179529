
import { defineComponent, PropType } from "vue";
import { formatDateTime } from "common";
import { renderText } from "common/datatable";
import { AjaxData, Api } from "datatables.net";
import DataTable from "datatables.net-vue3";
import VueMultiSelect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";

interface Row {
	id: number;
	from: string;
	subject: string;
	recipients: string|null;
	createdAt: number;
	timeAgo: string;
}

function wrap(tag: "div"|"span", classes: string): (data: string|null) => string {
	const escapedClasses = renderText(classes);

	return function(data: string|null): string {
		const escapedText = renderText(data);

		return `<${tag} class="${escapedClasses}">${escapedText}</${tag}>`;
	};
}

interface Recipient {
	id: number;
	emailAddress: string;
}

interface Group {
	id: number;
	name: string;
}

export default defineComponent({
	components: {
		DataTable,
		VueMultiSelect,
	},
	props: {
		showFilters: { type: Boolean, default: false },
		sources: { type: Array as PropType<Array<string>>, default: () => [] },
		recipients: { type: Array as PropType<Array<Recipient>>, default: () => [] },
		groups: { type: Array as PropType<Array<Group>>, default: () => [] },
	},
	data() {
		return {
			sourcesFilter: [] as Array<string>,
			recipientsFilter: [] as Array<Recipient>,
			groupsFilter: [] as Array<Group>,
		};
	},
	computed: {
		options() {
			return {
				autoWidth: false,
				serverSide: true,
				searching: this.showFilters,
				ajax: {
					url: "/email/datatable",
					data: (d: AjaxData&Record<string, unknown>): AjaxData&Record<string, unknown> => {
						d.sources_filter = this.sourcesFilter;
						d.recipients_filter = this.recipientsFilter.map((r: Recipient) => r.id);
						d.groups_filter = this.groupsFilter.map((g: Group) => g.id);

						return d;
					},
					error(_jqxhr: unknown, _status: unknown, error: unknown) {
						if (error === "Forbidden") {
							// User was logged out, reload to redirect to login page and also set the current page as the redirect target after login
							window.location.reload();
						} else {
							alert(error);
						}
					},
				},
				columns: [
					{ data: "from", orderable: false, render: renderText },
					{
						data: "recipients",
						orderable: false,
						render: wrap("div", "height-limited-row"),
					},
					{
						data: "groups",
						orderable: false,
						render: wrap("div", "height-limited-row"),
					},
					{
						data: "subject",
						orderable: false,
						render: wrap("div", "height-limited-row"),
					},
					{
						data: (row: Row) => {
							return "<span style="
								+ `"float: right" class="badge rounded-pill bg-info" title="${formatDateTime(new Date(row.createdAt * 1000))}">`
								+ row.timeAgo
								+ "</span>"
							;
						},
						orderable: false,
					},
				],
				order: [],
			};
		},
	},
	methods: {
		selectEmail(event: Event): void {
			const dt = this.$refs.datatable as typeof DataTable;
			const datatable = dt.dt as Api<Row>;
			if (event.target instanceof Element) {
				const row = event.target.closest("tr");
				if (row === null) {
					return;
				}
				if (row.parentElement?.tagName.toLowerCase() !== "tbody") {
					// Only want body rows
					return;
				}
				const table = row.closest(".email-table");
				if (table === null) {
					// Wrong table
					return;
				}
				const emptyTd = row.querySelector(".dataTables_empty");
				if (emptyTd !== null) {
					// Table is empty
					return;
				}
				this.$emit("emailSelected", datatable.row(row).data().id);
			}
		},
	},
	emits: ["emailSelected"],
});
