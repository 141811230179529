
import { assert } from "common";
import { defineComponent } from "vue";

const component = defineComponent({
	props: {
		html: { type: String, required: true },
	},
	data() {
		return {
			shadow: null as ShadowRoot|null,
		};
	},
	mounted() {
		assert(this.$refs.el instanceof Element);
		this.shadow = this.$refs.el.attachShadow({ mode: "open" });
		this.update(this.html);
	},
	methods: {
		update(html: string): void {
			assert(this.shadow !== null);
			this.shadow.innerHTML = html;
		},
	},
	watch: {
		html(html: string): void {
			this.update(html);
		},
	},
});

export default component;

