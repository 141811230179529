import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, mergeProps as _mergeProps, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c8144782"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-content" }
const _hoisted_2 = { class: "modal-header" }
const _hoisted_3 = { class: "modal-title" }
const _hoisted_4 = { class: "modal-body" }
const _hoisted_5 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, {
    to: _ctx.teleportTarget,
    disabled: _ctx.disableTeleport
  }, [
    _createVNode(_Transition, {
      appear: "",
      name: "slide-fade"
    }, {
      default: _withCtx(() => [
        (_ctx.show)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              style: _normalizeStyle([{"display":"block"}, `--bs-modal-zindex: ${_ctx.modalZIndex}`]),
              class: "modal",
              tabindex: "-1",
              role: "dialog"
            }, [
              _createElementVNode("div", _mergeProps({
                class: "modal-dialog",
                role: "document"
              }, _ctx.$attrs), [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("h5", _hoisted_3, [
                      _renderSlot(_ctx.$slots, "title", {}, undefined, true)
                    ]),
                    _createElementVNode("button", {
                      type: "button",
                      class: "btn-close",
                      "aria-label": "Close",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
                    })
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _renderSlot(_ctx.$slots, "body", {}, undefined, true)
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _renderSlot(_ctx.$slots, "footer", {}, () => [
                      _createElementVNode("button", {
                        type: "button",
                        class: "btn btn-danger",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
                      }, "Close")
                    ], true)
                  ])
                ])
              ], 16)
            ], 4))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }),
    _createVNode(_Transition, {
      appear: "",
      name: "fade"
    }, {
      default: _withCtx(() => [
        (_ctx.show)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "modal-backdrop fade show",
              style: _normalizeStyle(`--bs-backdrop-zindex: ${_ctx.backdropZIndex}`)
            }, null, 4))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 8, ["to", "disabled"]))
}