export function assert(condition: boolean): asserts condition {
	if (!condition) {
		throw new Error("Assertion failed");
	}
}

export function assertNever(_arg: never) {} // eslint-disable-line @typescript-eslint/no-empty-function

export function checkHasProperty<P extends string>(obj: object, property: P): obj is { [p in P]: unknown } {
	return property in obj;
}

/**
 * Type guard to return object with unknown properties, rather than object with no properties like the `object` type.
 */
export function isObject(obj: unknown): obj is Record<string|number|symbol, unknown> {
	return typeof obj === "object" && obj !== null;
}

/**
 * Format a date to YYYY-MM-DD
 */
export function dateToSystemFormat(date: Date): string {
	const formatted = date.toISOString().split("T")[0];
	assert(typeof formatted === "string"); // ISO standard format is YYYY-MM-DDTHH:mm:ss.sssZ as long as the year is not 5 digits long

	return formatted;
}

/**
 * Format a Date object, a YYYY-MM-DD formatted string, or an RFC3339 string into a local date format.
 *
 * @param {Date|string} date
 * @returns string
 */
export function formatDate(date: Date|string): string {
	if (typeof date === "string") {
		if (date.length === 10) {
			// Date only
			// JS date parsing is totally broken, easier to do it manually
			const parts = date.split("-");
			if (typeof parts[0] !== "string" || typeof parts[1] !== "string" || typeof parts[2] !== "string") {
				throw new Error("Invalid date");
			}
			date = new Date(
				parseInt(parts[0]),
				parseInt(parts[1]) - 1,
				parseInt(parts[2]),
			);
		} else {
			// Date is assumed to include time in RFC3339 format, JS parsing of RFC3339 is well-defined.
			date = new Date(date);
		}
	}

	return date.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" });
}

/**
 * Format a Date object or an RFC 3339 date-time string into a local datetime format.
 *
 * @param {Date|string} date
 * @returns string
 */
export function formatDateTime(date: Date|string): string {
	if (typeof date === "string") {
		// RFC 3339 parsing is actually well-defined, even though every other part of JS date handling sucks.
		date = new Date(date);
	}

	return formatDate(date) + " " + date.toLocaleTimeString("en-US");
}

globalThis.formatDate = formatDate;
globalThis.formatDateTime = formatDateTime;
