import DataTables from "datatables.net";
import { assert } from "./index";

const textDisplay = DataTables.render.text().display;
assert(typeof textDisplay === "function");

const renderText = (data: string|null): string => {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const text = textDisplay(data, null, null, { row: -1, col: -1, settings: {} }) ?? "";
	assert(typeof text === "string");

	return text || "";
};

function saveStateInUrlFragment(data: Record<string, unknown>): void {
	const fragment = btoa(JSON.stringify(data)).replace(/=*$/, "");
	const url = new URL(window.location.toString());
	url.hash = fragment;
	window.history.replaceState(null, "", url.toString());
}

function loadStateFromUrlFragment(): Record<string, unknown>|null {
	const fragment = atob(window.location.hash.slice(1));
	if (fragment === "") {
		return null;
	}
	const data = JSON.parse(fragment) as Record<string, unknown>;

	if (typeof data !== "object") {
		return null;
	}

	return data;
}

export {
	renderText,
	saveStateInUrlFragment,
	loadStateFromUrlFragment,
};
