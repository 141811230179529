
import { formatDateTime, isObject } from "common";
import { defineComponent } from "vue";
import ModalWindow from "./ModalWindow.vue";
import ShadowDom from "./ShadowDom.vue";

interface RecipientsForRule {
	rule: {id: number; name: string}|null;
	group: {id: number; name: string}|null;
	recipients: Array<{id: number; emailAddress: string}>;
}
type Recipients = Array<RecipientsForRule>;

interface Email {
	id: number;
	from: string;
	subject: string;
	recipients: Recipients;
	body: string;
	attachments: Array<{ id: number; filename: string }>;
	createdAt: number;
}

interface Permissions {
	editRule: boolean;
	editGroup: boolean;
	editRecipient: boolean;
}

declare const permissions: Permissions;

const component = defineComponent({
	components: {
		ModalWindow,
		ShadowDom,
	},
	props: {
		show: { type: Boolean, required: true },
		id: { type: Number, required: true },
	},
	data() {
		return {
			email: null as Email|null,
			loading: false,
			error: false,

			showRecipients: false,

			permissions,
		};
	},
	emits: [
		"close",
	],
	computed: {
		recipientsString(): string {
			if (this.email === null) {
				return "";
			}

			const recipients = new Set();
			for (const recipientsForRule of this.email.recipients) {
				for (const recipient of recipientsForRule.recipients) {
					recipients.add(recipient.emailAddress);
				}
			}

			return [...recipients].sort().join(", ");
		},
	},
	watch: {
		id: {
			handler: async function(id: number): Promise<void> {
				try {
					this.loading = true;
					const response = await fetch(`/email/${id}`, {
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
						},
					});

					// Make sure the user hasn't tried to open another email while this one was still loading
					if (id === this.id) {
						try {
							const data: unknown = await response.json();
							if (!isObject(data) || typeof data.id !== "number") {
								throw data;
							}
							this.email = data as any as Email; // eslint-disable-line @typescript-eslint/no-explicit-any
						} finally {
							this.loading = false;
						}
					}
				} catch (error) {
					this.error = true;
				}
			},
			immediate: true,
		},
	},
	methods: {
		formatDateTime,
	},
});

export default component;
