
import { defineComponent, PropType } from "vue";

const component = defineComponent({
	props: {
		show: { type: Boolean, required: true },
		teleportTarget: { type: String, default: "body" },
		disableTeleport: { type: Boolean, default: false },
		modalZIndex: { type: [Number, String, null] as PropType<number|string|null>, default: 1055 },
		backdropZIndex: { type: [Number, String, null] as PropType<number|string|null>, default: 1050 },
	},
	inheritAttrs: false,
	emits: [
		"close",
	],
});

export default component;
