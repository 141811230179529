import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ca4fe01"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "date-inputs" }
const _hoisted_2 = ["value"]
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", null, [
      _createTextVNode(" Start: "),
      _createElementVNode("input", {
        type: "date",
        max: "9999-12-31",
        required: "true",
        class: "form-control",
        value: _ctx.rangeStart,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:rangeStart', $event.target.value)))
      }, null, 40, _hoisted_2)
    ]),
    _createElementVNode("label", null, [
      _createTextVNode(" End: "),
      _createElementVNode("input", {
        type: "date",
        max: "9999-12-31",
        required: "true",
        class: "form-control",
        value: _ctx.rangeEnd,
        onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:rangeEnd', $event.target.value)))
      }, null, 40, _hoisted_3)
    ])
  ]))
}